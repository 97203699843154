import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/css/style.css";

import "@rainbow-me/rainbowkit/styles.css";
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
// import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { global } from "./config/global";
import { staticConfig } from "./components/static";
import merge from "lodash.merge";

const WalletTheme = merge(
  darkTheme({
    borderRadius: "small",
  }),
  {
    blurs: {
      modalOverlay: "large",
    },
    colors: {
      modalText: "#c7c7c7",
    },
  }
);

const WalletAvatar = ({ address, ensImage, size }) => {
  return ensImage ? (
    <img
      alt="avatar"
      src={ensImage}
      width={size}
      height={size}
      style={{ borderRadius: size }}
    />
  ) : (
    <Jazzicon diameter={size} seed={jsNumberForAddress(address)} />
  );
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
  global.chain,
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: staticConfig.PROJECT,
  projectId: staticConfig.PROJECT_ID,
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        avatar={WalletAvatar}
        theme={WalletTheme}
        modalSize="compact"
      >
        {/* <RainbowKitProvider chains={chains} theme={WalletTheme} modalSize="compact"> */}
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);
