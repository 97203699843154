import { ConnectButton } from "@rainbow-me/rainbowkit";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

export const ConnectWallet = (props) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        props.setWalletConnected(connected);
        return (
          <div
            className=""
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div className="flex items-center lg:space-x-2">
                    <button
                      onClick={openConnectModal}
                      className="font-bold primary-filled-button"
                    >
                      Connect
                    </button>
                  </div>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    className="font-bold primary-filled-button"
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div className="flex gap-[20px]">
                  <button
                    onClick={openChainModal}
                    className="hidden lg:flex gap-[10px] items-center text-[16px] px-[20px] py-[10px]"
                    disabled={
                      props.compoundPending === true ||
                      props.claimEthPending === true ||
                      props.claimChibaPending === true ||
                      props.unstakePending === true
                        ? true
                        : false
                    }
                  >
                    <div className="w-[40px] h-[40px] flex justify-center items-center">
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 32,
                            height: 32,
                            borderRadius: 999,
                            overflow: "hidden",
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? "Chain icon"}
                              src={chain.iconUrl}
                              style={{ width: 32, height: 32 }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    {account.displayBalance}
                  </button>
                  <button
                    onClick={openAccountModal}
                    className="flex gap-[10px] items-center text-[16px] px-[20px] py-[10px] font-bold"
                    disabled={
                      props.compoundPending === true ||
                      props.claimEthPending === true ||
                      props.claimChibaPending === true ||
                      props.unstakePending === true
                        ? true
                        : false
                    }
                  >
                    <div
                      style={{
                        borderRadius: "25px",
                        overflow: "hidden",
                        padding: "0px",
                        margin: "0px",
                        width: "50px",
                        height: "50px",
                        display: "inline-block",
                        background: "rgb(247, 63, 1)",
                      }}
                    >
                      <Jazzicon
                        diameter={49}
                        seed={jsNumberForAddress(account.address)}
                      />
                    </div>
                    {account.displayName}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
