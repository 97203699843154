import React, { useState, useEffect } from "react";

import { global } from "../config/global";
import {
  writeContract,
  prepareWriteContract,
  waitForTransaction,
} from "@wagmi/core";
import { toast } from "react-toastify";
import StakingContractABI from "../assets/abi/stakingContract.json";
import { staticConfig } from "../components/static";
import { getDefaultGas } from "../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { parseUnits, formatUnits } from "viem";
// import { getRealTime } from "../hooks/useStakingContractStatus";

export default function Stake(props) {
  const [stakeAmount, setStakeAmount] = useState();
  const [unstakeAmount, setUnstakeAmount] = useState(0.01);

  const [stakeButtonMsg, setStakeButtonMsg] = useState("Stake");
  const [unstakeButtonMsg, setUnstakeButtonMsg] = useState("UnStake");
  const [stakeErrMsg, setStakeErrMsg] = useState(false);
  const [unstakeErrMsg, setUnstakeErrMsg] = useState(false);
  const [pending, setPending] = useState(false);
  const [stakeStatus, setStakeStatus] = useState("");

  useEffect(() => {
    if (!props) {
      setStakeButtonMsg("LOADING...");
      setUnstakeButtonMsg("LOADING...");
      setStakeErrMsg("Please wait! Loading...");
      setUnstakeErrMsg("Please wait! Loading...");
    } else if (!props.walletConnected) {
      setStakeButtonMsg("Wallet Disconnected");
      setUnstakeButtonMsg("Wallet Disconnected");
      setStakeErrMsg("Connect wallet first!");
      setUnstakeErrMsg("Connect wallet first!");
    } else if (pending) {
      setStakeButtonMsg("Pending ");
      setUnstakeButtonMsg("Pending ");
      setStakeErrMsg("Please wait! Pending...");
      setUnstakeErrMsg("Please wait! Pending...");
    } else if (props.maticBalance < getDefaultGas()) {
      setStakeButtonMsg("Insufficient POL");
      setUnstakeButtonMsg("Insufficient POL");
      setStakeErrMsg("Insufficient POL for gasfee! Please buy more POL!");
      setUnstakeErrMsg("Insufficient POL for gasfee! Please buy more POL!");
    } else {
      const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60;
      // console.log(
      //   "-------- Claimable Date=",
      //   getRealTime(Number(props.stakeInfo.stakingTime) + Number(thirtyDaysInMilliseconds))
      // );

      if (
        !stakeAmount ||
        Number(stakeAmount) < 0 ||
        Number(stakeAmount) === 0
      ) {
        setStakeButtonMsg("Claim");
        setStakeErrMsg("Please enter valid POL amount!");
      } else if (Number(stakeAmount) > props.maticBalance) {
        setStakeButtonMsg("Insufficient POL");
        setStakeErrMsg("Insufficient POL token. Please buy more POL!");
      } else if (props.allowance < Number(stakeAmount)) {
        setStakeButtonMsg("Approve");
        setStakeErrMsg(
          "Insufficient allowance amount. Please increase allowance amount!"
        );
      } else {
        setStakeButtonMsg("Stake");
        setStakeErrMsg("Please wait! Staking...");
      }

      // if (
      //   !unstakeAmount ||
      //   Number(unstakeAmount) < 0 ||
      //   Number(unstakeAmount) === 0
      // ) {
      //   setUnstakeButtonMsg("Claim");
      //   setUnstakeErrMsg("Please enter valid UBXS token amount!");
      // } else 
      
      if (
        Number(unstakeAmount) >
        Number(
          formatUnits(
            Number(props.rewardAmount),
            global.UBXSDecimals
          )
        )
      ) {
        setUnstakeButtonMsg("Invalid UBXS amount");
        setUnstakeErrMsg("Please enter valid UBXS token amount to unstake!");
      } else if (
        (Number(props.stakeInfo.stakingTime) + Number(thirtyDaysInMilliseconds)) *
          1000 >
        Number(Date.now())
      ) {
        setUnstakeButtonMsg("Not reached time");
        setUnstakeErrMsg("Please wait to unstake!");
      } else {
        setUnstakeButtonMsg("Unstake");
        setUnstakeErrMsg("Please wait! Unstaking...");
      }
    }

    if (Number(props.stakeInfo.stakingTime) > 0 && props.walletConnected) {
      setStakeButtonMsg("Already Staked");
      setStakeErrMsg(
        "POL is already staked. Please stake after UBXS is claimed!"
      );
      setStakeStatus("Staked");
    } else if (Number(props.stakeInfo.stakingTime) === 0 && props.walletConnected) {
      setUnstakeButtonMsg("Not Staked");
      setUnstakeErrMsg("POL is not staked yet. Please stake POL!");
      setStakeStatus("Unstaked");
    } else if (!props.walletConnected) {
      setStakeStatus("");
    }
  }, [
    stakeAmount,
    unstakeAmount,
    pending,
    props.maticBalance,
    // props.allowance,
    props.refresh,
    props.walletConnected
  ]);

  const handleApprove = async () => {
    setPending(true);
    try {
      let dataApprove = {
        chainId: global.CHAIN_ID,
        address: global.STAKING_CONTRACTS, //ubxsTokenContractAddress,
        abi: StakingContractABI,
        functionName: "approve",
        args: [global.STAKING_CONTRACTS, staticConfig.MAX_UINT256],
      };
      const preparedData = await prepareWriteContract(dataApprove);
      const writeData = await writeContract(preparedData);
      const txData = await waitForTransaction(writeData);
      if (txData && txData.status === "success") {
        toast.success(`Successfully Approved! 👌`);
        // setApproved(true)
      } else {
        toast.error("Error! Approving is failed.");
      }
    } catch (error) {
      console.log(error);
      try {
        if (error?.shortMessage) {
          toast.error(error?.shortMessage);
        } else {
          toast.error("Unknown Error! Something went wrong.");
        }
      } catch (error) {
        toast.error("Error! Something went wrong.");
      }
    }
    try {
      if (props.setRefresh !== undefined && props.refresh !== undefined) {
        props.setRefresh(!props.refresh);
      }
    } catch (error) {}

    setPending(false);

    return;
  };

  const handleStake = async () => {
    setPending(true);
    try {
      let dataStaking = {
        chainId: global.CHAIN_ID,
        address: global.STAKING_CONTRACTS,
        abi: StakingContractABI,
        functionName: "stake",
        value: parseUnits(stakeAmount.toString(), global.MaticDecimals),
      };
      const preparedData = await prepareWriteContract(dataStaking);
      const writeData = await writeContract(preparedData);
      const txData = await waitForTransaction(writeData);
      if (txData && txData.status === "success") {
        toast.success(`Successfully Staked! 👌`);
        handleClose();
      } else {
        toast.error("Error! Staking is failed.");
      }
    } catch (error) {
      console.log(error);
      try {
        if (error?.shortMessage) {
          toast.error(error?.shortMessage);
        } else {
          toast.error("Unknown Error! Something went wrong.");
        }
      } catch (error) {
        toast.error("Error! Something went wrong.");
      }
    }
    try {
      if (props.setRefresh !== undefined && props.refresh !== undefined) {
        props.setRefresh(!props.refresh);
      }
    } catch (error) {}
    setPending(false);
    return;
  };

  const handleUnstake = async () => {
    try {
      setPending(true);

      const dataS = {
        address: global.STAKING_CONTRACTS,
        abi: StakingContractABI,
        functionName: "unstake",
        chainId: global.CHAIN_ID,
      };
      const preparedDataS = await prepareWriteContract(dataS);
      const writeDataS = await writeContract(preparedDataS);
      const txDataS = await waitForTransaction(writeDataS);

      if (txDataS && txDataS.status === "success") {
        toast.success(`Successfully Claimed! 👌`);
        handleClose();
      } else {
        toast.error("Error! Claiming is failed.");
      }
    } catch (error) {
      console.error(error);
      try {
        if (error?.shortMessage) {
          toast.error(error?.shortMessage);
        } else {
          toast.error("Unknown Error! Something went wrong.");
        }
      } catch (error) {
        toast.error("Error! Something went wrong.");
      }
    }

    try {
      if (props.setRefresh !== undefined && props.refresh !== undefined) {
        props.setRefresh(!props.refresh);
      }
    } catch (error) {}

    setPending(false);

    return;
  };

  const handleStakeBtn = async () => {
    // if (stakeButtonMsg === "Stake") {
      await handleStake();
    // } else if (stakeButtonMsg === "Approve") {
    //   await handleApprove();
    // } else toast.warn(stakeErrMsg);
  };

  const handleUnstakeBtn = async () => {
    if (unstakeButtonMsg === "Unstake") {
      await handleUnstake();
    } else toast.warn(unstakeErrMsg);
  };

  const handleClose = () => {
    setStakeAmount();
  };


  const formatAmount = (value, decimals) => {
    const formattedValue = formatUnits(Number(value), decimals);
    return isNaN(formattedValue) ? "0" : formattedValue;
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full flex flex-col lg:flex-row gap-12 ">
        <div className="w-full lg:w-1/2 flex flex-col gap-8 border border-[#152837]  bg-[#1d1d2ba0] rounded-[32px] p-6 transition-all duration-300 hover:shadow-card">
          <div className="flex flex-col select-none">
            <span className="text-[24px] text-[#a8adbf]">Your POL</span>
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <span className="flex items-center gap-2 font-bold break-all text-[36px] text-[#239eda]">
                <img
                  alt="ubxs"
                  className="w-12 h-12 ml-[-10px]"
                  src="/image/matic/polygon-icon-circle.svg"
                />
                {props.maticBalance.toFixed(3)}
              </span>
              <span className="text-[24px] font-normal md:font-semibold text-[#239eda]">
              POL
              </span>
            </div>
            <span className="text-[14px] text-[#6b6f81]">
              ${(props.maticBalance * props.maticPrice).toFixed(3)}
            </span>
          </div>
          <div className="flex flex-col w-full space-y-2 select-none">
            <div className="flex flex-col justify-between gap-2 whitespace-nowrap md:flex-row">
              <span className="text-[16px] text-[#a8adbf]">Total Amount</span>
              {/* <span className="text-[16px] text-[#6b6f81]">
                Approx: <strong>{props.allowance}</strong> POL
              </span> */}
            </div>
            <div className="flex h-14 w-full items-center justify-between rounded-xl border border-[#4e536480] bg-[#2f3548] pr-5 text-[#a8adbf] rtl:pl-5">
              <input
                disabled={pending}
                onChange={(e) => setStakeAmount(e.target.value)}
                value={stakeAmount}
                type="number"
                placeholder="0.00"
                className="h-12 w-full rounded-xl border-none bg-[transparent] text-[#a8adbf] placeholder-[#6b6f81] focus:outline-none focus:ring-0"
              />
              <button
                onClick={() => setStakeAmount(props.maticBalance)}
                className="h-9 rounded-xl border border-[#4e5364] bg-[#1d1d2b] px-4 text-[#a8adbf]"
              >
                max
              </button>
            </div>
          </div>
          <hr className="flex w-[calc(100%+48px)] border-[1px] border-[#4e536480] ml-[-24px]" />
          <button
            onClick={handleStakeBtn}
            className={`text-[18px] font-bold text-white rounded-full py-[15px] ${
              stakeStatus !== "Staked"
                ? "bg-[#0082b3] hover:bg-[#00a3e0]"
                : "border border-[#0082b3]"
            }`}
          >
            <div>
              {stakeButtonMsg}
              {pending ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="sm"
                  className="animate-spin"
                />
              ) : (
                <></>
              )}
            </div>
          </button>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col gap-8 border border-[#152837]  bg-[#1d1d2ba0] rounded-[32px] p-6 transition-all duration-300 hover:shadow-card">
          <div className="flex flex-col select-none">
            <span className="text-[24px] text-[#a8adbf]">Your UBXS</span>
            <div className="flex items-center space-x-2 rtl:space-x-reverse">
              <span className="flex items-center gap-2 font-bold break-all text-[36px] text-[#239eda]">
                <img
                  alt="ubxs"
                  className="w-8 h-8"
                  src="/image/bixos/bxs-icon.svg"
                />
                {parseFloat(formatAmount(
                  Number(props.rewardAmount),
                  global.UBXSDecimals
                )).toFixed(2)}
              </span>
              <span className="text-[24px] font-normal md:font-semibold text-[#239eda]">
                UBXS
              </span>
            </div>
            <span className="text-[14px] text-[#6b6f81]">
              $
              {(
                formatUnits(
                  Number(props.rewardAmount),
                  global.UBXSDecimals
                ) * props.ubxsPrice
              ).toFixed(2)}
            </span>
          </div>
          <div className="flex flex-col w-full space-y-2 select-none">
            <div className="flex flex-col justify-between gap-2 whitespace-nowrap md:flex-row">
              <span className="text-[16px] text-[#a8adbf]">Claim Amount</span>
            </div>
            <div className="flex h-14 w-full items-center justify-between rounded-xl border border-[#4e536480] bg-[#2f3548] pr-5 text-[#a8adbf] rtl:pl-5">
              <input
                disabled={pending}
                // onChange={(e) => setUnstakeAmount(e.target.value)}
                value={parseFloat(formatAmount(
                  Number(props.rewardAmount),
                  global.UBXSDecimals
                )).toFixed(2)}
                type="number"
                placeholder="0.00"
                className="h-12 w-full rounded-xl border-none bg-[transparent] text-[#a8adbf] placeholder-[#6b6f81] focus:outline-none focus:ring-0"
                readOnly                
              />
            </div>
          </div>
          <hr className="flex w-[calc(100%+48px)] border-[1px] border-[#4e536480] ml-[-24px]" />
          <button
            onClick={handleUnstakeBtn}
            className={`text-[18px] font-bold text-white rounded-full py-[15px] ${
              stakeStatus !== "Unstaked"
                ? "bg-[#0082b3] hover:bg-[#00a3e0]"
                : "border border-[#0082b3]"
            }`}
          >
            <div>
              {unstakeButtonMsg}
              {pending ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="sm"
                  className="animate-spin"
                />
              ) : (
                <></>
              )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
