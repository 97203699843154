import React, { useState } from "react";
import { formatUnits } from "viem";
import {
  getRealTime,
  useStakingContractStatus,
} from "../hooks/useStakingContractStatus";
import { ConnectWallet } from "../components/ConnectWallet";
import { global } from "../config/global";
import Stake from "../components/Stake";

export default function StakingPage() {
  const [refresh, setRefresh] = useState(false);

  const [walletConnected, setWalletConnected] = useState(false);

  const {
    maticBalance,
    allowance,
    maticPrice,
    ubxsPrice,
    totalUBXS,
    rewardAmount,
    stakeInfo,
  } = useStakingContractStatus(refresh);
  const realTime = getRealTime(stakeInfo.stakingTime, false);

  const formatAmount = (value, decimals) => {
    const formattedValue = formatUnits(Number(value), decimals);
    return isNaN(formattedValue) ? "0" : formattedValue;
  };


  return (
    <div
      className="font-panton flex flex-col min-h-screen  bg-cover bg-no-repeat"
      style={{ backgroundImage: `url('/image/4.jpg')` }}
    >
      <div className="flex flex-col gap-[20px] items-center">
        <div className="z-50 w-full flex justify-between items-center px-10 lg:px-10 py-2 border-b border-[#152837]">
          <div className="h-full">
            <div className="hidden lg:flex items-center space-x-2">
              <img src="/image/bixos/bixos-header-logo.svg" alt="logo" />
            </div>
            <div className="flex lg:hidden gap-[10px] items-center space-x-2">
              <img src="/image/bixos/bixos-mobil-icon.svg" alt="logo" />
            </div>
          </div>
          <div className="flex gap-[20px] items-center">
            <ConnectWallet
              setWalletConnected={(val) => {
                if (val === undefined) setWalletConnected(false);
                else setWalletConnected(val);
              }}
            />
          </div>
        </div>
      </div>
      <div className="min-h-[76vh]">
        <div className="container py-4 space-y-12 lg:py-12">
          <div className="flex flex-col lg:flex-row gap-[20px] lg:gap-0 justify-start rounded-lg lg:justify-between items-center border border-[#00a3e0] pt-[20px] pb-0 lg:pb-[20px] z-10">
            <div className="w-full lg:w-1/4 flex flex-col border-b lg:border-b-0 lg:border-r border-[#00a3e0] px-[20px] lg:px-[40px]">
              <span className="text-[18px]">All Staked POL</span>
              <span className="text-[24px] lg:text-[32px] font-bold">
              {formatAmount(totalUBXS, 18)}
              </span>
            </div>
            <div className="w-full lg:w-1/4 flex flex-col border-b lg:border-b-0 lg:border-r border-[#00a3e0] px-[20px] lg:px-[40px]">
              {/* <span className="text-[18px]">All Staked UBXS Value</span>
              <span className="text-[24px] lg:text-[32px] font-bold">
                $
                {(
                  formatUnits(Number(totalUBXS), global.UBXSDecimals) *
                  ubxsPrice
                ).toFixed(3)}
              </span> */}
              <span className="text-[18px]">Staked Time</span>
              <span className="text-[24px] lg:text-[32px] font-bold">
                {realTime}
              </span>
            </div>
            <div className="w-full lg:w-1/4 flex flex-col border-b lg:border-b-0 lg:border-r border-[#00a3e0] px-[20px] lg:px-[40px]">
              <span className="text-[18px]">Your Staked POL</span>
              <span className="text-[24px] lg:text-[32px] font-bold">
              {formatAmount(stakeInfo.stakingAmount, global.MaticDecimals)}{" "}{" "}
              POL
                {/* {" "}/ $
                {(formatUnits(
                  Number(stakeInfo.stakingAmount),
                  global.MaticDecimals
                ) * maticPrice).toFixed(3)} */}
              </span>
            </div>
            <div className="w-full lg:w-1/4 flex flex-col border-[#00a3e0] px-[20px] lg:px-[40px]">
              <span className="text-[18px]">Your Reward UBXS</span>
              <span className="text-[24px] lg:text-[32px] font-bold">
              {parseFloat(formatAmount(rewardAmount, global.UBXSDecimals)).toFixed(2)}{" "}{" "}
                UBXS
                {/* {" "}/ $
                {(formatUnits(
                  Number(stakeInfo.rewardAmount),
                  global.UBXSDecimals
                ) * ubxsPrice).toFixed(3)} */}
              </span>
            </div>
          </div>
          <div className="flex flex-col flex-1 items-center">
            <Stake
              setRefresh={setRefresh}
              refresh={refresh}
              walletConnected={walletConnected}
              maticBalance={maticBalance}
              allowance={allowance}
              maticPrice={maticPrice}
              ubxsPrice={ubxsPrice}
              stakeInfo={stakeInfo}
              rewardAmount={rewardAmount}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-background-primary">
        <div className="container flex flex-col items-center justify-between w-full py-2 space-y-5 lg:space-y-0 lg:flex-row">
          <div className="flex flex-col">
            <a href="https://bixos.com/">
              <img
                alt="footer-logo"
                className="w-full h-16"
                src="/image/bixos/bixos-logo-white.svg"
              />
            </a>
            <div className="text-[16px] text-[#627D93]">
              <span className="capitalize">copyright © 2024 bixos inc.</span>
            </div>
          </div>
          <div className="flex space-x-5 rtl:space-x-reverse">
            <a className="lg:h-15 lg:w-15" href="https://t.me/ubxscommunity">
              <img src="/image/bixos/telegram-icon.svg" alt="telegram" />
            </a>
            <a
              className="lg:h-15 lg:w-15"
              href="https://discord.com/invite/2JKmtjkYFt"
            >
              <img src="/image/bixos/discord-icon.svg" alt="discord" />
            </a>
            <a className="lg:h-15 lg:w-15" href="https://twitter.com/Bixosinc">
              <img src="/image/bixos/twitter-icon.svg" alt="twitter" />
            </a>
            <a
              className="lg:h-15 lg:w-15"
              href="https://www.linkedin.com/company/bixosinc"
            >
              <img src="/image/bixos/linkedin-icon.svg" alt="linkedin" />
            </a>
            <a
              className="lg:h-15 lg:w-15"
              href="https://www.instagram.com/bixosinc/"
            >
              <img src="/image/bixos/instagram-icon.svg" alt="instgram" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
