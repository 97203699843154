import { useEffect, useState } from "react";
import StakingContractABI from "../assets/abi/stakingContract.json";
import OracleContractABI from "../assets/abi/oracleContract.json";
import { useAccount } from "wagmi";
import { readContract, fetchBalance } from "@wagmi/core";
import { global } from "../config/global";
import { formatUnits } from "viem";
// import { formatUnits } from "viem";
import axios from "axios";
import { ethers } from 'ethers';

export function getRealTime(timeStamp, showTime) {
  if (Number(timeStamp) === 0 || isNaN(Number(timeStamp))) return "-";
  console.log('timeStamp', Number(timeStamp));

  const date = new Date(Number(timeStamp) * 1000); // Convert seconds to milliseconds
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  const formattedDate =
    showTime === true
      ? `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      : `${year}-${month}-${day}`;
  console.log(formattedDate);

  return formattedDate;
}

export function useStakingContractStatus(refresh) {
  const [data, setData] = useState({
    maticBalance: 0, // Amount of connected account's CHIBA tokens
    allowance: 0,
    maticPrice: 0, // Matic Real Price
    ubxsPrice: 0, // UBXS Token Real Price
    totalUBXS: 0, // Total amount of staked
    rewardAmount: 0,
    stakeInfo: {}, // Stake info of connected wallet {nftId, maticAmount, claimableUBXS, stakingTime}
  });

  const { address } = useAccount();

  const [refetch, setRefetch] = useState(false);

  // Function to get stake info of user account
  async function getStakesInfo(userAddress) {
    const stakesInfo = await readContract({
      address: global.STAKING_CONTRACTS,
      abi: StakingContractABI,
      functionName: "stakingInfos",
      args: [userAddress],
      chainId: global.CHAIN_ID,
    });

    const info = {
      stakingAmount: stakesInfo[0],
      stakingTime: stakesInfo[1],
    };

    return info;
  }

  // Function to get Total amount of claimable UBXS
  async function getTotalUBXS(userAddress) {
    const stakingAmount = await readContract({
      address: global.STAKING_CONTRACTS,
      abi: StakingContractABI,
      functionName: "totalStakingAmount",
      chainId: global.CHAIN_ID,
    });


    return stakingAmount;
  }

  async function getRewardAmount(userAddress) {
    const rewardAmount = await readContract({
      address: global.STAKING_CONTRACTS,
      abi: StakingContractABI,
      functionName: "getRewardAmount",
      args: [userAddress],
      chainId: global.CHAIN_ID,
    });

    return rewardAmount;
  }


  // Used to update interface information
  useEffect(() => {
    const timerID = setInterval(() => {
      setRefetch((prevData) => {
        return !prevData;
      });
    }, global.REFETCH_INTERVAL);

    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("--------wallet address=", address);

        // 1. Get amount of matic exists in wallet account
        const walletBalance = address
          ? parseFloat((await fetchBalance({ address })).formatted)
          : 0;
        console.log("--------wallet_matic_balance=", walletBalance);

        // 2. Get Stake information of connected wallet account
        const userStakeInfo = address
          ? await getStakesInfo(address)
          : { stakingAmount: 0, stakingTime: 0 };
        console.log("--------staked_info=", userStakeInfo);
        console.log(
          "--------Real_time=",
          getRealTime(userStakeInfo.stakingTime, false)
        );

        // 3. Get Total amount of claimable UBXS token (not only user account)
        const totalUBXS = await getTotalUBXS(address);
        console.log("--------total_ubxs_amount=", totalUBXS);
        const rewardAmount = await getRewardAmount(address);
        console.log("--------total_reward_amount=", rewardAmount);
        const ubxsPriceData = await readContract({
          address: global.oracleAddress,
          abi: OracleContractABI,
          functionName: 'getPrice',
          args: [global.UBXS_TOKEN.address],
          chainId: global.CHAIN_ID, // Sepolia chain ID
        });
        console.log('jerry ubxsPriceData', ubxsPriceData)
        const ubxs = parseFloat(formatUnits(Number(ubxsPriceData[0]), Number(ubxsPriceData[1])));

        const maticPriceData = await readContract({
          address: global.oracleAddress,
          abi: OracleContractABI,
          functionName: 'getPrice',
          args: [global.polAddress],
          chainId: 137, // Sepolia chain ID
        });
        const matic = parseFloat(parseFloat(formatUnits(Number(maticPriceData[0]), Number(maticPriceData[1]))));
        console.log("--------UBXS_Price=", ubxs);
        console.log("--------Matic_Price=", matic);

        setData({
          maticBalance: walletBalance,
          allowance: 0,
          maticPrice: matic,
          ubxsPrice: ubxs,
          totalUBXS: totalUBXS,
          rewardAmount: rewardAmount,
          stakeInfo: userStakeInfo,
        });
      } catch (error) {
        console.log("useStakingContractStatus err", error);
      }
    };
    fetchData();
  }, [address, refetch, refresh]);

  return data;
}
