import { polygon, sepolia } from "wagmi/chains";
import ubxs_ic from "../assets/img/taos.png";

// export const sepolia = {
//   id: 11_155_111,
//   name: 'Sepolia',
//   nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH', decimals: 18 },
//   rpcUrls: {
//     default: {
//       http: ['https://endpoints.omniatech.io/v1/eth/sepolia/public'],
//     },
//   },
//   blockExplorers: {
//     default: {
//       name: 'Etherscan',
//       url: 'https://sepolia.etherscan.io',
//       apiUrl: 'https://api-sepolia.etherscan.io/api',
//     },
//   },
//   contracts: {
//     multicall3: {
//       address: '0xca11bde05977b3631167028862be2a173976ca11',
//       blockCreated: 751532,
//     },
//     ensRegistry: { address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e' },
//     ensUniversalResolver: {
//       address: '0xc8Af999e38273D658BE1b921b88A9Ddf005769cC',
//       blockCreated: 5_317_080,
//     },
//   },
//   testnet: true,
// }


export const IS_PRODUCT_MODE = true; // TODO true

export const staking_contract_address = {
  // Polygon Deployed Staking Contract
  mainnet: "0xc4111fF7c92E46302bD76547510CDEc276a53c27",
  testnet: "0x871BE589ef874dbAa4AE7470478d3957201Ca15C",
};

export const oracleAddress = "0xb5d880d456380f1F9EE5EbBB0e0eE35da9093189";
export const polAddress = "0x0000000000000000000000000000000000000000";

export const ubxsToken_mainnet = {
  name: "UBXS",
  address: "0x4f1960E29b2cA581a38c5c474e123f420F8092db",
  decimals: 6,
  logo: ubxs_ic,
  isNative: true,
};

export const ubxsToken_testnet = {
  name: "UBXS",
  address: "0x2780823B3f811AbB8E3639feC5436cb02eF2A8B4",
  decimals: 6,
  logo: ubxs_ic,
  isNative: true,
};

export const global = {
  STAKING_CONTRACTS: IS_PRODUCT_MODE
    ? staking_contract_address.mainnet
    : staking_contract_address.testnet,
  oracleAddress: oracleAddress,
  UBXS_TOKEN: IS_PRODUCT_MODE ? ubxsToken_mainnet : ubxsToken_testnet,
  chain: IS_PRODUCT_MODE ? [polygon] : [sepolia],
  defaultGas: IS_PRODUCT_MODE ? 0.0005 : 0.0004,
  REFETCH_INTERVAL: 30000,
  MaticDecimals: 18,
  UBXSDecimals: 6,
  polAddress: polAddress,
  CHAIN_ID: 137
  // CHAIN_ID: 11155111
};
